@charset "utf-8";

// =================
// CUSTOMIZING BULMA
//
// [Bulma](https://bulma.io) offers great support for customization
// and modularity.
// More info here: https://bulma.io/documentation/customize/
//
// We do not customize much of Bulma yet, but we have the setup to do so.
// =================

@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
// 1. Set your own initial variables and derived
//    variables in _variables.scss
@import "variables";

// 2. Setup your Custom Colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);
$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);
$github: #333;
$github-invert: findColorInvert($github);

@import "~bulma/sass/utilities/derived-variables";

// 3. Add new color variables to the color map.
$addColors: (
  "twitter": (
    $twitter,
    $twitter-invert
  ),
  "linkedin": (
    $linkedin,
    $linkedin-invert
  ),
  "github": (
    $github,
    $github-invert
  )
);
$colors: map-merge($colors, $addColors);
// 3. Finally, run Bulma with all our changes
@import "~bulma/bulma";

// ========================
// END OF CUSTOMIZING BULMA
// ========================
/* App wide styles */
// Full height body with a tint color
body {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// App-wide styles
#root {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  flex: 1;
}
nav {
  a {
    font-weight: bold;
    color: #2c3e50;
    &.is-active {
      color: #136abb !important;
    }
  }
}
[class*=" icon"],
[class^="icon"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  line-height: 1;
  position: relative;
  top: -0.05em;
  vertical-align: middle;
}

.main-section .columns {
  min-height: calc(100vh - 10.5rem);
}

.main-section.forecast .columns {
  min-height: inherit;
  margin-bottom: 2em;
}

.main-section .column {
  padding: 0.75rem;
}
.main-section .hero-body {
  align-items: flex-start;
}
#mainSection {
  background: linear-gradient(135deg, #7ccaae 0, #0a65b6 100%);
}
.is-content {
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #7a7a7a;
  font-weight: 600;
  padding: 1.25rem;
  position: relative;
  height: 100%;
}
/* login */
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.avatar span {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
ul.is-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
.module-list {
  display: flex;
  flex-wrap: wrap;
}
.module-list li {
  flex-grow: 1;
}
.module-list figure.image {
  margin-top: 1em;
  margin-bottom: 1em;
}
.module-name {
  color: grey;
}
.landing {
  padding-top: 1rem;
}
.landing__wizards,
.landing__comments {
  height: 100%;
  background: white;
}
.landing__comments {
  padding: 1rem;
}
.header {
  text-align: left;
  padding: 1rem;
}
.task-column {
  text-align: center;
}
.instructor-item {
  border-radius: 5px;
  display: flex;
  margin: 0 0 0.5rem 0;
  background: white;
}
.instructor-item-content {
  padding: 1rem;
}
.instructor-item .instructor-code {
  margin: 0 1rem;
}
.instructor-item .status {
  background: #449d7a;
  flex-basis: 25px;
  border-radius: 5px 0px 0px 5px;
}
.instructor-item .status.status-warning {
  background: #f28c02;
}
.navbar-contextual {
  flex-grow: 1;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
}
.navbar-contextual ul {
  max-height: 30px;
  text-align: center;
  line-height: 14px;
}
.navbar-contextual li {
  font-size: 14px;
}
.info {
  background: white;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.info .header {
  background: #66c0c6;
  padding: 1rem;
  color: white;
  font-weight: bold;
  min-height: 55.31px;
}
.info .content {
  padding: 1rem;
}
.instructor-courses {
  background: white;
  display: flex;
  flex-direction: column;
}
.instructor-courses .header {
  background: #90cab3;
  color: white;
  font-weight: bold;
  padding: 1rem;
}
.availability {
  background: white;
}
.availability .header {
  background: #e0e0e0;
  color: white;
  font-weight: bold;
  padding: 1rem;
}
.mobile-nav {
  position: fixed;
  display: none;
  width: 100%;
  padding: 1rem;
  bottom: 0;
}
.mobile-nav .tabs {
  background: white;
}
.availability table {
  table-layout: fixed;
  width: 100%;
}
.availability th {
  text-align: center;
}
.availability td {
  height: 18px;
}
.ava-table {
  border: 0;
  font-size: 13px;
  color: #4f4f4f;
}
.ava-table td {
  padding: 0.3rem;
  border-color: #e0e0e0;
}
.ava-table th {
  border: 0;
  font-size: 16px;
  font-weight: 500;
}
.ava-table-modulo {
  text-align: right;
  border: 0;
}
.ava-table tr:first-child th {
  padding-top: 0.1rem;
  padding-bottom: 0.5rem;
  color: #333;
}
.cell-azul {
  background: #66c0c6;
}
.cell-verde {
  background: #90cab3;
}
.cell-cross {
  background: #449d7a;
}
.attrs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}
.attrs-item {
  padding: 5px 16px;
  text-align: center;
}
.attr-name {
  font-weight: 500;
}
.bio {
  display: flex;
  align-items: center;
  padding: 10px;
}
.bio-avatar span.icon {
}
.bio-info {
  margin: 0 1rem;
}
.bio-info-name {
  font-size: 18px;
  font-weight: bold;
}
.course-list {
  display: flex;
  flex-direction: column;
}
.course-list .course-list-item {
  display: flex;
  margin: 1.5rem 1rem;
  align-items: center;
}
.course-list .icon {
  margin: 0 5px;
}
.course-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.eye-on {
  color: #449d7a;
}
.eye-off {
  color: lightgrey;
}
.course-code {
  margin: 0 1rem;
}
.course-name {
  font-weight: 500;
}
.instructor-courses .add-course-btn {
  margin: 1rem;
}
.availability .availability-content {
  padding: 1rem 0.5rem 0.5rem 0rem;
}
.activity-list {
  padding: 0.5rem;
  padding-top: 0;
}
.activity-item {
  display: flex;
  margin: 1.5rem 0;
}
.activity-avatar {
  padding-top: 0.1rem;
}
.activity-content {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}
.activity-header {
  display: flex;
}
.activity-username {
  color: #333;
  line-height: 23px;
}
.activity-date {
  padding: 0 0.5rem;
  font-weight: 100;
  font-size: 14px;
  line-height: 24px;
}
.activity-body {
  font-weight: 100;
}
.notification {
  span {
    text-decoration: underline;
  }
}

//only for tests
.testFragment {
  text-align: center;
  padding-top: 5em;
}
.message a:not(.button):not(.tag):not(.dropdown-item) {
  text-decoration: none;
}

/**
* Demand editor 
*/

.is-content {
  &.demand__editor {
    padding: 0px;
    border-radius: 0px;
    background: inherit;
  }
}

//tables
.alignMiddle {
  vertical-align: middle !important;
}

.table {
  &.demand__editor,
  &.demand__resume {
    th,
    td {
      border-color: #e0e0e0;
      border-width: 0 1px 1px;
      color: #363636;
      vertical-align: middle;
      strong {
        font-weight: 500;
      }
      &:last-child {
        border-right: none;
      }
      &:first-child {
        border-left: none;
      }
    }
    th {
      font-size: 0.9em;
      line-height: 1.3em;
    }
    tr:last-child {
      border-bottom: 1px solid #e0e0e0;
    }
    input {
      border-radius: 0px;
      box-shadow: none;
      max-width: 79px;
      padding: 2px;
      margin: 0px;
      height: 34px;
      text-align: center;
    }
  }
}

.demand_editor__buttons {
  $blue-color: #2772b5;
  .button {
    &.is-link {
      font-weight: bold;
      background-color: $blue-color;
      &.is-outlined {
        background-color: inherit;
        border-color: $blue-color;
        color: $blue-color;
        &:hover {
          color: lighten($blue-color, 10%);
        }
      }
    }
  }
}

//search box - suggestion
.autosuggest__container {
  display: inline-block;
  width: 100%;
  input {
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(219, 219, 219);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0px;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(219, 219, 219);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(219, 219, 219);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(219, 219, 219);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: rgba(10, 10, 10, 0.1) 0px 1px 2px 0px inset;
    box-sizing: border-box;
    color: rgb(54, 54, 54);
    cursor: text;
    display: inline-flex;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 400;
    height: 36px;
    justify-content: flex-start;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    max-width: 100%;
    padding-bottom: 5px;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 5px;
    position: relative;
    text-align: start;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-size-adjust: 100%;
    text-transform: none;
    vertical-align: top;
    width: 100%;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -webkit-rtl-ordering: logical;
    -webkit-border-image: none;
  }
  &.isLoading {
    .react-autosuggest__container {
      &::after {
        position: absolute !important;
        right: 0.625em;
        top: 0.625em;
        z-index: 4;
        -webkit-animation: spinAround 0.5s infinite linear;
        animation: spinAround 0.5s infinite linear;
        border: 2px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        height: 1em;
        position: relative;
        width: 1em;
      }
    }
  }
}

.react-autosuggest__container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  width: 750px;
  -webkit-align-items: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(10, 10, 10, 0.1) 0px 1px 2px 0px inset;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 750px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none !important;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 2.5px 1em;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.is-content {
  min-height: 400px;
}

.filters__container {
  background-color: white;
  padding: 2em 2em 0em 2em;
  margin-bottom: 1em;
  .columns {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}

.content .loadingContainer {
  //padding-top: 100px;
}

.tabs.dashboardTabs {
  margin-bottom: 0px;
  li {
    margin: 0px;
  }
}

// buttons
.button.is-primary.is-inverted.is-outlined:focus {
  color: white;
  background-color: inherit;
}
